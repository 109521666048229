<template>
  <div>
    <b-img
      fluid
      :src="Logo"
      alt="Login V2"
    />
  </div>
</template>
<script>

import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ColorLogo',
  computed: {
    Logo() {
      if (this.skin === 'dark') return this.appLogoImageLight
      return this.appLogoImageDark
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const {
      appLogoImageDark, appLogoImageLight,
    } = $themeConfig.app
    return {
      skin,
      appLogoImageDark,
      appLogoImageLight,
    }
  },
}
</script>
<style scoped>
.slider-logo-svg .fil0{
  fill: #FAA70A!important;
}svg{
   max-height: 50px;
   max-width: 50px;
 }
</style>
